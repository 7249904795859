import { default as pressInit } from "./press.js";
import { submit as recaptchaSubmit, default as recaptchaInit } from "./recaptcha.js";
import { default as tabsInit } from "./tabs.js";
import { default as formsInit } from "./forms.js";
import { page as analyticsPage } from "./analytics.js";
import "./jquery.validate.base.js";
import "./jquery.validate.base.unobtrusive.js";
import "./jquery.validate.regex.js";
import accordion from "./accordion.js";

const localValues = {
	allow: true,
	fadeClick: true,
	offset: 50,
	limit: 730,
	backTo: false,
	type: null,
	newsletterAllow: true,
}

const updateVar = (variable, val) => localValues[variable] = val;

const autorun = function() {
	$('a.js-auto-popup-launch').click();
	$('a.js-auto-popup-launch').removeClass('js-auto-popup-launch');
}

const init = function() {

	// launch
	$('a.js-popup-launch').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {

			// if this class is present then close existing popups to prevent insane popup stacking

			if (link.is('.js-popup-launch-close')) close(true, false);

			launch(link.attr('href'), link.attr('data-template') || null);
			return false;
		});
	});

	// inject
	$('a.js-popup-inject').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {

			// if this class is present then close existing popups to prevent insane popup stacking

			if (link.is('.js-popup-inject-close')) close(true, false);

			inject(link.attr('href'), link.attr('data-template') || null);
			return false;
		});
	});

	// confirm
	$('a.js-popup-confirm').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {
			var title = link.attr('data-title') || 'Confirm';
			var text = link.attr('data-text') || 'Are you sure? This cannot be undone.';
			var yesValue = link.attr('data-yes-value') || 'Confirm';
			var noValue = link.attr('data-no-value') || 'Cancel';
			confirm(title, text, link.attr('href'), false, yesValue, noValue);
			return false;
		});
	});

	// image
	$('a.js-popup-image').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {
			image(link.attr('data-title') || '', link.attr('href'));
			return false;
		});
	});

	// gallery
	$('a.js-popup-gallery').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {
			var json = link.attr('data-api-url');
			if (json != null) {
				gallery(json);
			}
			return false;
		});
	});

	// share
	$('a.js-popup-share').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {
			var title = link.attr('data-title');
			var url = link.attr('data-url');
			var image = link.attr('data-image') || '';
			share(title, url, image);
			return false;
		});
	});

	// newsletter
	$('.js-newsletter-sign-up:not(.js-newsletter-sign-up-done)').each(function() {
		var wrapper = $(this);
		var form = wrapper.find('form');
		// submit
		form.off('submit').on('submit', function() {
			if (!localValues.newsletterAllow || !form.valid()) return false;
			localValues.newsletterAllow = false;
			var email = form.find('.form-text--email').val(),
				url = form.attr('action') + '?email=' + email;
			newsletter(url, email);
			return false;
		});

		// done
		wrapper.addClass('js-newsletter-sign-up-done');
	});

	// close
	$('.m-popup .js-close, #popupConfirmNo, .m-photo-gallery__inner').each(function() {
		var link = $(this);
		// click
		link.off('click').on('click', function() {
			close(false, link.hasClass('js-close-print'));
			return false;
		});
	});

	// fade click
	$('#popupFade').off('click').on('click', function() {
		if (localValues.fadeClick) {

			close(false);

		}

		return false;
	})

	//Dont close the popup gallery if content is clicked
	$('.m-photo-gallery__content').off('click').on('click', function() {
		return false;
	});

	//esc hides gallery popup
	document.onkeydown = function(evt) {
		evt = evt || window.event;
		var isEscape = false;
		if ("key" in evt) {
			isEscape = (evt.key == "Escape" || evt.key == "Esc");
		} else {
			isEscape = (evt.keyCode == 27);
		}
		if (isEscape) {
			if ($('#popupPhotoGallery').is(':visible')) {
				$('#popupPhotoGallery, #popupFade').hide();
			}
		}
	};

	// forms
	forms();

	autorun();
}


const loader = function(hide, keepFade, fancy) {
	var p = fancy ? $('#popupLoaderAdv') : $('#popupLoader');
	var f = $('#popupFade');

	if (hide) {
		p.hide();
		p.removeClass('show');
		if (!keepFade) {
			f.hide();
		}
		localValues.allow = true;
		localValues.fadeClick = false;
		return;
	}
	if (localValues.allow) {
		p.addClass('show');
		p.show();
		f.show();
		localValues.allow = false;
		localValues.fadeClick = false;
	}
}
const inject = function(url, template) {

	// check if current url
	var comp = url;
	var path = location.pathname;

	// remove comp slash
	if (comp.substr(-1) == '/') {
		comp = comp.substr(0, comp.length - 1);
	}

	// remove path slash
	if (path.substr(-1) == '/') {
		path = path.substr(0, path.length - 1);
	}

	// redirect
	if (comp == path) {
		return false;
	}


	if (typeof template === 'undefined' || template === null) {
		template = '#popupLaunchTemplate';
	}

	if (typeof template === 'undefined') {
		return false;
	}

	template = $(template);
	// set type
	localValues.type = 'inject';

	// show loader
	loader();

	// get popup content
	$.ajax({
		url: url,
		cache: false,
		success: function(response) {
			template.html(response);
			// set top
			template.css('top', getTop());
			// init
			init();
			// show
			template.add('#popupFade:hidden').show();
			// hide loader
			loader(true, true);
			localValues.allow = true;
			localValues.fadeClick = false;

			window.site.body.append(template);
			accordion();
		},
		error: function(e) {
			// hide loader
			loader(true);
			localValues.allow = true;
			localValues.fadeClick = true;
			// show notice
			confirm('Error', 'Sorry, the URL "' + url + '" could not be shown (' + e.statusText.toLowerCase() + ').', '', true);
		}
	});
}
const launch = function(url, template) {
	const _template = template;
	// allowed check
	if ((!localValues.allow || window.site.win.width() < localValues.limit || localValues.backTo) && url != "/profileform") {

		if (url === '' || url === '#') {
			location.reload();
			return false;
		}

		location.href = url;
		return false;
	}

	// check if current url
	var comp = url;
	var path = location.pathname;

	// remove comp slash
	if (comp.substr(-1) == '/') {
		comp = comp.substr(0, comp.length - 1);
	}

	// remove path slash
	if (path.substr(-1) == '/') {
		path = path.substr(0, path.length - 1);
	}

	// redirect
	if (comp == path) {
		return false;
	}

	// set type
	localValues.type = 'launch';

	// show loader
	loader();

	// get template
	if (typeof template === 'undefined' || template === null) {
		template = '#popupLaunchTemplate';
	}
	template = $(template).html();
	if (typeof template === 'undefined') {
		location.href = url;
		return;
	}
	// parse template
	template = Hogan.compile(template);

	// hide open popups
	var visible = $('.m-popup:visible');
	if (visible.length > 0) {
		// set backto
		localValues.backTo = true;
		// set attr
		visible.attr('data-hidden', 'true').hide();
	}

	// get page content
	$.ajax({
		url: url,
		cache: false,
		success: function(response) {
			var content = $(response).find('#popupContentWrapper').html();
			if (typeof content === 'undefined') {
				location.href = url;
				return;
			}
			// render template
			var rendered = template.render({
				content: content,
				url: url
			});
			// add template to body
			window.site.body.append(rendered);
			// get popup
			var p = $('.m-popup[data-href="' + url + '"]');
			// set top
			p.css('top', getTop());
			// init
			init();
			// show
			p.add('#popupFade:hidden').show();
			// hide loader
			loader(true, true);
			localValues.allow = true;
			localValues.fadeClick = true;
			// analytics
			analyticsPage(url, 'Popup');

			window.updateDynamicPhoneNumber()
			tabsInit();

			if (_template === '#popupProfileTemplate') {
				import("/js/profile-form.js")
					.then((module) => {
						module.default();
					})
					.catch((err) => console.log(err.message));
			}

			if (url == "/profileform") {
				pressInit()
			}

			recaptchaInit();
			formsInit()
		},
		error: function(e) {
			// hide loader
			loader(true);
			localValues.allow = true;
			localValues.fadeClick = true;
			// show notice
			confirm('Error', 'Sorry, the URL "' + url + '" could not be shown (' + e.statusText.toLowerCase() + ').', '', true);
		}
	});
}

const image = function(title, image) {
	// allowed check
	if (!localValues.allow || localValues.backTo) {
		close();
	}
	localValues.allow = false;

	// get template
	var template = Hogan.compile($('#popupImageTemplate').html());

	// render template
	var rendered = template.render({
		Title: title,
		Image: image
	});
	// add template to body
	window.site.body.append(rendered);

	// set type
	localValues.type = 'image';

	// get popup
	var p = $('#popupImage');
	// set top
	p.css('top', getTop());
	// init
	init();
	// show
	p.add('#popupFade').show();
	// allow popups
	localValues.allow = true;
}

const gallery = function(url) {

	// allowed check
	if (!localValues.allow || localValues.backTo) {
		return false;
	}

	// loader
	loader();
	localValues.allow = false;

	$.ajax({
		url: url,
		cache: true,
		success: function(responseString) {
			var response = JSON.parse(responseString);

			if (response == null || response.length < 1) {
				// hide loader
				loader(true);
				localValues.allow = true;
				localValues.fadeClick = true;
				return;
			}

			var images = [];

			// get images
			for (var i = 0; i < response.length; i++) {
				var img = response[i];
				images.push({
					Index: i,
					Number: (i + 1),
					Title: img.Alt,
					Description: img.Description,
					ImageUrl: img.Url
				});
			}

			// get template
			var template = Hogan.compile($('#popupGalleryTemplate').html());

			// render template
			var rendered = template.render({
				Images: images,
				Description: response[0].Description,
				Max: response.length
			});

			// add template to body
			window.site.body.append(rendered);

			// set type
			localValues.type = 'small';

			// get popup
			var p = $('#popupPhotoGallery');
			// set top
			p.css('top', getTop());
			// init
			init();

			// Import the photo-gallery here as loading it via critical cant guarantee the template is in place yet with the current setup
			import("/js/photo-gallery.js")
				.then((module) => {
					module.default();
				})
				.catch((err) => console.log(err.message));

			// hide loader
			loader(true);
			// show
			p.add('#popupFade').show();

			// allow popups
			localValues.allow = true;


		},
		error: function(e) {
			// hide loader
			loader(true);
			localValues.allow = true;
			localValues.fadeClick = true;
			// show notice
			confirm('Error', 'Sorry, the URL "' + url + '" could not be shown (' + e.statusText.toLowerCase() + ').', '', true);
		}
	})

}

const confirm = function(title, text, url, notice, yesValue, noValue) {
	// allowed check
	if (!localValues.allow || localValues.backTo) {
		close();
	}
	localValues.allow = false;

	// get template
	var template = Hogan.compile($('#popupConfirmTemplate').html());

	var popupTitle = title || 'Confirm';
	if (popupTitle === 'none') {
		popupTitle = '';
	}

	// render template
	var rendered = template.render({
		title: popupTitle,
		text: text || 'Are you sure? This cannot be undone.',
		url: url,
		isNotice: !notice,
		confirm: yesValue || 'Confirm',
		cancel: noValue || 'Cancel'
	});

	// add template to body
	window.site.body.append(rendered);

	// set type
	localValues.type = 'small';

	// get popup
	var p = $('#popupConfirm');
	// set top
	p.css('top', getTop());
	// init
	init();
	// show
	p.add('#popupFade').show();
	// allow popups
	localValues.allow = true;
}

const share = function(title, url, image) {
	// allowed check
	if (!localValues.allow || localValues.backTo) {
		close();
	}
	localValues.allow = false;

	// get template
	var template = Hogan.compile($('#popupShareTemplate').html());
	// render template
	var rendered = template.render({
		title: encodeURIComponent(title),
		url: encodeURIComponent(url),
		image: encodeURIComponent(image)
	});
	// add template to body
	window.site.body.append(rendered);

	// set type
	localValues.type = 'small';

	// get popup
	var p = $('#popupShare');
	// set top
	p.css('top', getTop());
	// init
	init();
	window.site.doc.trigger('ajaxComplete');
	// show
	p.add('#popupFade').show();
	// allow popups
	localValues.allow = true;
}

const newsletter = function(url, email) {

	// allowed check
	if (!localValues.allow || window.site.win.width() < localValues.limit || localValues.backTo) {
		location.href = url;
		return false;
	}

	// check if current url
	var comp = url;
	var path = location.pathname;

	// remove comp slash
	if (comp.substr(-1) == '/') {
		comp = comp.substr(0, comp.length - 1);
	}

	// remove path slash
	if (path.substr(-1) == '/') {
		path = path.substr(0, path.length - 1);
	}

	// redirect
	if (comp == path) {
		return false;
	}

	// set type
	localValues.type = 'launch';

	// show loader
	loader();

	// define template
	var template = $('#popupLaunchTemplate').html();
	if (typeof template === 'undefined') {
		location.href = url;
		return;
	}

	// parse template
	template = Hogan.compile(template);

	// hide open popups
	var visible = $('.m-popup:visible');
	if (visible.length > 0) {
		// set backto
		localValues.backTo = true;
		// set attr
		visible.attr('data-hidden', 'true').hide();
	}

	// get page content
	$.ajax({
		url: url,
		cache: false,
		success: function(response) {
			var content = $(response).find('#popupContentWrapper').html();

			if (typeof content === 'undefined') {
				location.href = url;
				return;
			}
			// render template
			var rendered = template.render({
				content: content,
				url: url
			});

			// add template to body
			window.site.body.append(rendered);
			recaptchaInit();
			// get popup
			var p = $('.m-popup[data-href="' + url + '"]');
			// set top
			p.css('top', getTop());
			// set email
			p.find('.form-text--email').val(email);
			// init
			init();
			// show
			p.add('#popupFade:hidden').show();
			// hide loader
			loader(true, true);
			localValues.allow = true;
			localValues.fadeClick = true;
			// analytics
			analyticsPage(url, 'Popup');

			window.updateDynamicPhoneNumber()

			localValues.newsletterAllow = true;

		},
		error: function(e) {
			// hide loader
			loader(true);
			localValues.allow = true;
			localValues.fadeClick = true;
			// show notice
			confirm('Error', 'Sorry, the URL "' + url + '" could not be shown (' + e.statusText.toLowerCase() + ').', '', true);
			localValues.newsletterAllow = true;
		}
	});
}

const forms = function() {
	$('.m-popup form:not(.js-form-done)').each(function() {
		var form = $(this);
		var button = form.find(':submit');

		// submit
		form.on('submit', function() {
			// reset validation
			form.removeData('validator');
			form.removeData('unobtrusiveValidation');
			// validate form
			$.validator.unobtrusive.parse(form);
			// check
			if (form.valid() && recaptchaSubmit) {
				// disable button
				button.addClass('disabled').prop('disabled', true);
				// submit
				$.ajax({
					type: 'POST',
					url: form.attr('action'),
					data: form.serialize(),
					cache: false,
					success: function(response) {
						// remove error
						form.prev('.js-ajax-error:first').remove();
						// check
						if (response.Valid) {
							// close popups
							close();
							// open redirect
							launch(response.Url);
						}
						else {
							localValues.allow = true;
							// add error
							form.before('<p class="form-error js-ajax-error">' + response.Message + '</p>');
						}
					},
					error: function() {
						// remove error
						form.prev('.js-ajax-error:first').remove();
						// add error
						form.before('<p class="form-error js-ajax-error">Sorry, there was an unexpected server error.</p>');
					},
					complete: function() {
						// enable button
						button.removeClass('disabled').prop('disabled', false);
					}
				});
			}
			return false;
		});

		// done
		form.addClass('js-form-done');
	});
}

const close = function(keepFade, print) {
	var p = $('.m-popup:not([data-visible]):visible');
	if (!localValues.allow) {
		return;
	}
	localValues.allow = false;
	// hide loader
	loader(true, true);
	// hide popups
	if (localValues.backTo) {
		localValues.backTo = false;
		$('.m-popup[data-hidden]').removeAttr('data-hidden').show();
	}
	else if (!keepFade) {
		// hide fade
		$('#popupFade').hide();
	}
	// remove popup
	p.remove();
	// clear type
	localValues.type = null;
	// trigger print
	if (print) {
		window.print();
	}
	// allow popups
	localValues.allow = true;
	// allow fade click
	localValues.fadeClick = true;
}

const getTop = function() {
	var top = window.site.win.scrollTop();
	var offset = localValues.offset;
	return parseInt(top += offset, 10);
}

window.site.doc.ajaxComplete(function() {
	init();
});

export default init;
export { loader, confirm, localValues as popupsVariables, updateVar, close, init, getTop }

